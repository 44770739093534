<template>
  <div>
    <ui-modal ref="Modal" v-bind="modalProps" v-on="$listeners">
      <el-form ref="form" :rules="rules"  :inline="true"  :model="CreateTask"  label-width="auto" hide-required-asterisk>
        <el-form-item label="短信标题" prop="task_theme" >
          <el-input  v-model="CreateTask.task_theme"  clearable placeholder="请输入任务标题"/>
        </el-form-item>
        <el-form-item label="月">
          <el-date-picker value-format="yyyy-M"  v-model="yearMonth" format="yyyy-M" @change="calculateDate" :picker-options="pickerOptions"  type="month" style="width:185px"></el-date-picker>
        </el-form-item>
        <el-form-item label="日" prop="scheduler_day" >
          <el-input v-model="scheduler_params.scheduler_day"   clearable  @change="changeDay" plain onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8" placeholder="日"/>
        </el-form-item>
        <el-form-item label="时">
          <el-time-picker  v-model="value" value-format="HH:mm:ss"  :picker-options="{selectableRange: '00:00:00 - 23:59:59'}" style="width:185px"></el-time-picker>
        </el-form-item>
        <el-form-item label="过滤近期发送"  prop="filter_condition">
          <el-radio v-model="CreateTask.filter_condition" :label="1" :disabled="CreateTask.value ?true : false">近1天</el-radio>
          <el-radio v-model="CreateTask.filter_condition" :label="3" :disabled="CreateTask.value ?true : false">近3天</el-radio>
          <el-radio v-model="CreateTask.filter_condition" :label="7" :disabled="CreateTask.value ?true : false">近7天</el-radio>
          <el-input  v-model="CreateTask.value" style="width:70px" @input="filterDay"  onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"></el-input>天
        </el-form-item>
        <el-form-item label="过滤已购天数" prop="purchase_time_filter">
          <el-radio v-model="CreateTask.purchase_time_filter" :label="0" :disabled="CreateTask.value1 ?true : false">不过滤</el-radio>
          <el-radio v-model="CreateTask.purchase_time_filter" :label="1" :disabled="CreateTask.value1 ?true : false">近1天</el-radio>
          <el-radio v-model="CreateTask.purchase_time_filter" :label="3" :disabled="CreateTask.value1 ?true : false">近3天</el-radio>
          <el-radio v-model="CreateTask.purchase_time_filter" :label="7" :disabled="CreateTask.value1 ?true : false">近7天</el-radio>
          <el-input  v-model="CreateTask.value1" style="width:70px"  @input="filterBuy" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"></el-input>天
        </el-form-item>
      </el-form>
    </ui-modal>
  </div>
</template>
<script>
import {errorAlert,successAlert}from "../../../utils/alert"
export default {
  props: ["row"],
  data() {
    const { row } = this;
    return {
      value:'',
      value1:'',
      pickerOptions: {disabledDate(time) {return time.getTime() < Date.now() - 8.64e7;}},
      ranParam:{...row},
      dayCount:"",
      yearMonth:'',
      value:'',
      value1:new Date(),
      modalProps: {
        title: "创建定时任务",
        okText: "确认创建",
        width: "50%",
          onSubmit: async () => {
            await this.$refs.form.validate();
              if(this.yearMonth){
                let array=this.yearMonth.split("-");
                this.scheduler_params.scheduler_year = array[0]
                this.scheduler_params.scheduler_month = array[1]
              }
              if(this.value){
                let arr = this.value.split(":");
                this.scheduler_params.scheduler_hour = arr[0]
                this.scheduler_params.scheduler_minute = arr[1]
                this.scheduler_params.scheduler_second = arr[2]
              }else{
                return  this.$message({
                message: '警告,您没有选择准确时间，请重新操作',
                type: 'warning'
                });
              }
              if( this.ranParam.sql_text == undefined){
                return  this.$message({
                  message: '警告,您还没有查询出结果，请返回操作',
                  type: 'warning'
                });
              }
              if(!this.ranParam.smsTemplatContent.includes("@var(short_url)")){
                Object.keys(this.scheduler_params).forEach(item=>{
                  if(this.isEmpty(this.scheduler_params[item])){
                    delete this.scheduler_params[item]
                  }
                })
                let params={
                  task_theme:this.CreateTask.task_theme,
                  template_id:this.ranParam.template_id,
                  filter_condition:this.CreateTask.filter_condition,
                  purchase_time_filter:this.CreateTask.purchase_time_filter,
                  sql_text:this.ranParam.sql_text,
                  scheduler_params: this.scheduler_params,
                };
                const res = await this.$http.post("/periodic_task/scheduler_job/create",params);
                  if(res.data.code ==0){
                      successAlert(res.data.msg)
                  }else{
                    errorAlert(res.data.msg)
                  }

              }else{
                  Object.keys(this.scheduler_params).forEach(item=>{
                    if(this.isEmpty(this.scheduler_params[item])){
                      delete this.scheduler_params[item]
                    }
                  })
                let params={
                  long_url:this.CreateTask.long_url,
                  task_theme:this.CreateTask.task_theme,
                  template_id:this.ranParam.template_id,
                  filter_condition:this.CreateTask.filter_condition,
                  purchase_time_filter:this.CreateTask.purchase_time_filter,
                  sql_text:this.ranParam.sql_text,
                  scheduler_params: this.scheduler_params,
                }
              this.$prompt('由于您选中的短信模版中含短网址参数,请输入长链接URL:', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  model:"CreateTask.long_url",
                  inputPattern:  /^(?:(http|https|ftp):\/\/)?((?:[\w-]+\.)+[a-z0-9]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/i,
                  inputErrorMessage: 'URL格式不正确'
                }).then(({ value }) => {
                      params.long_url = value
                      this.TimingtTask(params)
                      }).catch(()=>{
                          this.$message({
                          type: 'info',
                          message: '取消输入'
                        });       
                      });
                }
        },
      },
     
        CreateTask:{
          task_theme:"",
          template_id:"",
          long_url:"",
          filter_condition:null,
          purchase_time_filter:null,
          sql_text:"",
          scheduler_params:{}
        },

        scheduler_params:{
          scheduler_type:"cron",
          scheduler_year: null,
          scheduler_month:null,
          scheduler_day:"",
          scheduler_hour:"",
          scheduler_minute:"",
          scheduler_second:""
        },

      rules: {
        task_theme: [{ required: true, message: "请输入任务标题", trigger: "blur" }],
        filter_condition: [{ required: true, message: '请选择过滤近期发送'}],
        purchase_time_filter: [{ required: true, message: '请选择过滤已购天数'}],
      },
    };
  },

  methods: {
    
    async TimingtTask(params){
      const res =  await this.$http.post("/periodic_task/scheduler_job/create",params)
        if(res.data.code ==0){
          successAlert(res.data.msg)
        }else{
          errorAlert(res.data.msg)
        }
    },
    
    filterDay(val){
      this.CreateTask.filter_condition=val
    },

    filterBuy(val){
      this.CreateTask.purchase_time_filter=val
    },
   
    changeDay(val){
      if(this.dayCount){
        if(val>this.dayCount){
          this.scheduler_params.scheduler_day = ""
          return this.$alert('您填写的日期超过该月天数或填写了当前日期之前!请检查','错误提示',{
            confirmButtonText: '确定',
            callback:action=>{
              this.$message({
                type: 'error',
                message: `错误输入提示: ${ val}`
              })
            }
          })
        }
      }else{
          if(val>31){
          this.scheduler_params.scheduler_day = ""
          return this.$alert('您填写的日期无效!','错误提示',{
            confirmButtonText: '确定',
            callback:action=>{
              this.$message({
                type: 'error',
                message: `错误输入提示: ${val}`
              })
            }
          })
        }
      }
    },
    
      calculateDate (val){
        if(val){
          let arr=val.split("-")
          this.dayCount =  this.getDayNumByYearMonth(arr[0],arr[1])
        }
      },
      
      getDayNumByYearMonth(year,month){
          switch(Number(month)){
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12:
                return 31;
            case 4:
            case 6:
            case 9:
            case 11:
                return 30;
            case 2:
                return this.isLeapYear(Number(year)) ? 29 : 28;
          }
      },
     
      isLeapYear(year){
        if(year/4 == 0 && year/100 != 0){
            return true ;
        } else if (year/400 == 0){
            return true ;
        } else{
            return false ;
        }
      },

      isEmpty(obj) {
        if (typeof obj === 'undefined' || obj === null || obj === '') {
          return true;
        } else {
          return false;
        }
      }
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-input--small .el-input__icon {
    line-height: 50px;
}
/deep/.el-form-item{
  padding-left: 10px !important;
}
.el-divider{
  cursor: pointer;
}
/deep/.el-form-item__label {
  margin-top: 10px;
  font-size: 14px;
}
/deep/.el-input--small .el-input__inner {
  margin-top: 10px;
}
/deep/.el-radio {
  margin-top: 20px;
}
/deep/ .el-form-item.is-error  .el-input__inner{
        border-color: #EBEEF5;
      } 
</style>
